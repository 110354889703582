<template >
  <v-container style="margin-top: 70px; margin-bottom: 70px;">
    <v-row class="mt-10 d-flex justify-center" v-if="showTable == false">
      <v-col cols="6" style="max-width: 500px;">
        <v-card class="pa-4">

          <v-img :src="require('../assets/logo.png')" class="my-3 mx-auto" contain
            :style="{ 'width': isResponsive ? '60%' : '100%' }" height="200" />

          <v-form @submit.prevent="submitHandler" ref="form">
            <v-card-text>
              <v-text-field v-model="phone" label="טלפון" type="phone" :rules="[v => !!v || 'שדה חובה']"
                placeholder="טלפון" required></v-text-field>
              <v-text-field v-model="password" label="סיסמה" :rules="[v => !!v || 'שדה חובה']" placeholder="סיסמה"
                required></v-text-field>

            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn @click="login" color="#635ddb">
                <span class="white--text px-8">כניסה</span>
              </v-btn>
            </v-card-actions>
            <v-row v-if="progressShow" class="my-5">
              <v-col class="d-flex justify-center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center" v-if="showTable">
      <v-col cols='12' lg='10'>
        <v-data-table id="table1" :headers="headers" :items="filteredCalls" sort-by="calldate" :sort-desc.sync="sortDesc"
          class="elevation-4"
          :footer-props="{ 'items-per-page-options': [20, 50, 100, -1], 'items-per-page-text': 'שורות בעמוד:' }">

          <template v-slot:top>
            <div class="d-flex justify-space-around pt-3">
              <span class="text-center">{{ "מנוי: " + phone }}</span>
              <span class="text-center " :style="{ color: textColor }">{{ tokef }}</span>
              <a href="#" class="text-center" style="text-decoration: underline;" @click="refreshPage()">התנתק</a>
            </div>
            <div class="divider"></div>
            <div class="d-flex flex-wrap pa-5">
              <span class="text-center flex-grow-1"
                style="font-weight: bold; font-size: 30px; font-family: Times, serif;">הסטוריית שיחות</span>
              <v-btn small color="black" dark class="mb-2 ms-2" style="max-width: 20px;">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" dark @click="downloadExcel()">
                      mdi-file-download
                    </v-icon>
                  </template>
                  <span>הורד לקובץ אקסל</span>
                </v-tooltip>
              </v-btn>
            </div>

          </template>



          <template v-slot:item.index="{ item, index }">
            <tr>
              <td>{{ index + 1 }}</td>
            </tr>
          </template>
          <template v-slot:no-data>
            <v-row v-if="progressShow" class="my-5">
              <v-col class="d-flex justify-center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </v-col>
            </v-row>
            <div class="ma-5">
              <span class="text-center" style="font-weight: bold; font-size: 25px; font-family: Times, serif;">אין נתונים
                למנוי זה</span>
            </div>
            <v-btn color="primary" @click="login">
              Reset
            </v-btn>

          </template>
        </v-data-table>

      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" :timeout="4000">
      {{ snacbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn :color="snackbarColorBt" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-container>
</template>

<script>
import TableToExcel from "@linways/table-to-excel";

export default {
  name: 'FormLogin',

  data: () => ({
    isResponsive: false, // Initially set to false
    details: {},
    phone: "",
    password: "",
    headers: [
      { text: '#', value: "index", sortable: false },
      {
        text: 'תאריך',
        align: 'start',
        value: 'calldate',
      },
      { text: 'מזהה', value: 'src' },
      { text: 'יעד', value: 'dst' },
      { text: 'סטטוס שיחה', value: 'disposition' },
      { text: 'משך שיחה', value: 'duration' },
      { text: 'סוג שיחה', value: 'did' },
    ],
    calls: [{}],
    sortDesc: true,
    tokef: "",
    textColor: "red",
    progressShow: false,
    showTable: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
  }),
  computed: {
    filteredCalls() {
      return this.calls.filter(call => call.did === 'blocked').map(call => {
        return {
          ...call,
          did: 'חסוי',
          disposition: call.disposition === 'ANSWERED' ? 'נענתה' :
            call.disposition === 'FAILED' ? 'נכשלה' :
              call.disposition === 'NO ANSWER' ? 'לא נענתה' :
                call.disposition === 'BUSY' ? 'תפוס' :
                  call.disposition,
          dst: call.dst === 'vm' ? 'תא קולי' : call.dst
        };
      }).sort((a, b) => new Date(b.calldate) - new Date(a.calldate));
    }
  },
  methods: {
    async login() {
      if (this.$refs.form.validate()) {
        this.progressShow = true
        let api = process.env.VUE_APP_HISTORY_URL;

        var formdata = new FormData();
        formdata.append("clid", this.phone);
        formdata.append("auth_pass", this.password);
        formdata.append("api_key", process.env.VUE_APP_API_KEY);

        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };

        fetch(api, requestOptions)
          .then(response => {
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
              return response.json();
            } else {
              return response.text();
            }
          }).then(result => {
            console.log(result);
            if (result.includes("no data")) {
              this.showTable = true;
              this.progressShow = false;
              this.showTokef();
            } else if (result.includes("username or password inccorect")) {
              this.showSnackBar("שגיאת התחברות, שם משתמש או סיסמה לא תקינים", "red");
              this.progressShow = false;
            } else {
              this.showTable = true;
              this.progressShow = false;
              this.calls = result;
              this.showTokef();
            }
          }).catch(error => {
            console.log('error', error)
          });
      }
    },
    showTokef() {
      let api = "http://cp.merkaz-hakavim.co.il/mobile_api.php?";

      var formdata = new FormData();
      formdata.append("req", "check_clid");
      formdata.append("data", this.phone);

      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      fetch(api, requestOptions)
        .then(response => response.text()).then(result => {
          console.log(result);
          var myresponse = result;
          var day = myresponse.substring(9, 13);
          var month = myresponse.substring(13, 17);
          var year = myresponse.substring(17, 19);

          if (myresponse === "{error: no such user}") {
            console.log("המנוי לא קיים");
          } else if (myresponse.includes("expired:")) {
            this.tokef = "המנוי אינו בתוקף, " + "הסתיים בתאריך: " + myresponse.replace(/expired: |{|}/g, '');
            console.log(myresponse);
          } else {
            this.textColor = "green"
            this.tokef = "המנוי פעיל, " + "בתוקף עד: " + year + month + day;
          }
        }).catch(error => {
          console.log('error', error)
        });
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    downloadExcel() {
      TableToExcel.convert(document.getElementById("table1"), {
        name: "callHistory.xlsx",
        sheet: {
          name: "Sheet 1"
        },
      });
    },
    refreshPage() {
      location.reload();
    },
    handleResize() {
      // Check if the window width is less than or equal to 600px
      if (window.innerWidth <= 500) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    }
  },
  beforeDestroy() {
    // Remove event listener before component is destroyed
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    if (window.innerWidth <= 500) {
      this.isResponsive = true; // Set to true if screen size is small
    }
    window.addEventListener('resize', this.handleResize);
  },
}
</script>
<style>
.divider {
  border-top: 0.5px solid #cfcccc;
  /* Set the border style, width, and color */
  margin-top: 10px;
  /* Optional: Add margin to adjust spacing */
  margin-bottom: 10px;
  /* Optional: Add margin to adjust spacing */
}

@media (max-width: 500px) {
  .d-flex.justify-space-around {
    flex-direction: column;
    align-items: center;
  }

  .d-flex.justify-space-around>* {
    margin-bottom: 1rem;
  }

  .d-flex.flex-wrap {
    flex-wrap: wrap;
  }

  .d-flex.flex-wrap>* {
    flex-basis: 100%;
    margin-bottom: 10px;
    /* Add margin between lines */

  }

}
</style>
